@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@layer base {
  @font-face {
    font-family: 'Bodoni-72';
    src: url('shared/utils/fonts/Bodoni-72.ttc');
  }

 span.motto {
    @apply font-custom;
  }
} 

.message-error p {
  margin-top: 2rem;
  color: red;
  font-size: 1rem;
}

.parent-donate-group {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-template-rows: repeat(2, 1fr);
  grid-column-gap: 1px;
  grid-row-gap: 1px;
}
  
.donate-online { grid-area: 1 / 1 / 3 / 2; }
.donate-bank-account { grid-area: 1 / 2 / 2 / 3; }
.donate-redirect-profit { grid-area: 2 / 2 / 3 / 3; }

@media only screen and (max-width: 1023px) {
  .parent-donate-group {
    grid-template-columns: 1fr;
    grid-template-rows: 1fr;
  }

  .donate-online { grid-area: auto; }
  .donate-bank-account { grid-area: auto; }
  .donate-redirect-profit { grid-area: auto; }
}